import { useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SwiperClass, SwiperSlide, Swiper } from "swiper/react";
import Text from "~/components/Text";
import { QUERY_GET_COLLECTIONS_BLOCKS } from "~/data/collections";
import { Product, ProductCategory } from "~/services/product";
import { transformProductItems } from "~/utils/transform";
import { getUrlCustom } from "~/utils/theme";
import { PATH } from "~/constants/enum";
import { trackingSelectItem, trackingViewListItem } from "~/services/tracking";
import styles from "./FeaturedProducts.module.scss";
import { Navigation } from "swiper/modules";
import classNames from "classnames";
import FeaturedProductsLoading from "~/components/Skeletons/FeaturedProductsLoading";
import useImpressions from "~/hooks/useImpressions";
import ProductPrice from "~/components/Items/ProductItem/ProductPrice";
import ProductName from "~/components/Items/ProductItem/ProductName";
import ProductImage from "~/components/Items/ProductItem/ProductImage";
import ProductItem from "~/components/Items/ProductItem";

type Props = {
  // identifier of collections
  identifier: string;
  activeTab: string;
  loading?: boolean;
};

const FeatureProductItem = ({ identifier, activeTab }: Props) => {
  const { initializeObserver, trackedSKUs, visibleSKUs, updateTrackedSKUs } =
    useImpressions();

  const [swiperRef, setSwiperRef] = useState<SwiperClass>({} as SwiperClass);
  const _slidesPerView =
    (swiperRef?.params?.slidesPerView as number) || (3 as number);
  const {
    data: dataCollection,
    loading: loadingCollection,
    fetchMore,
  } = useQuery(QUERY_GET_COLLECTIONS_BLOCKS, {
    variables: {
      url_key: identifier,
      page_size: 3,
      current_page: 1,
    },
    context: { fetchOptions: { method: "GET" } },
    skip: !identifier || activeTab !== identifier,
  });
  const _list = useMemo(() => {
    return (
      transformProductItems(dataCollection?.productCollection?.[0]?.items) || []
    );
  }, [dataCollection]);

  const page_info = useMemo(() => {
    return {
      total_count: dataCollection?.productCollection?.[0]?.total_count,
      current_page: dataCollection?.productCollection?.[0]?.current_page,
    };
  }, [dataCollection?.productCollection?.[0]]);

  const handleFecthMore = useCallback(() => {
    const current = page_info.current_page + 1;
    if (current <= page_info?.total_count) {
      fetchMore({
        variables: {
          current_page: current,
        },
        updateQuery(previousQueryResult, options) {
          const { fetchMoreResult } = options;
          if (!fetchMoreResult) return previousQueryResult;
          const result = Object.assign({}, previousQueryResult, {
            productCollection: [
              {
                ...fetchMoreResult.productCollection[0],
                items: [
                  ...previousQueryResult.productCollection[0].items,
                  ...fetchMoreResult.productCollection[0].items,
                ],
              },
            ],
          });
          return result;
        },
      });
    }
  }, [page_info]);

  const handleRealIndexChange = useCallback(
    (swiper: SwiperClass) => {
      if (!hasNext) return;
      if (!!loadingCollection) return;
      // if (products.length > MAX_RECORD) return;
      const currentIndex = swiper?.realIndex ?? 0;
      const silderPerView =
        typeof swiper?.params?.slidesPerView === "number"
          ? swiper?.params?.slidesPerView
          : 3;

      const remainingProducts = _list.length - currentIndex;

      if (remainingProducts <= silderPerView) {
        return handleFecthMore?.();
      }
    },
    [_list]
  );

  const hasNext = page_info?.total_count > page_info?.current_page;

  // check invisible product-list

  // Send tracked SKUs to Google Analytics
  const sendImpressions = useCallback(() => {
    const skusToTrack = Array.from(visibleSKUs).filter(
      (sku) => !trackedSKUs.has(sku)
    );

    if (skusToTrack.length > 0) {
      trackingViewListItem({
        listKey: identifier,
        products: _list.filter((product) => skusToTrack.includes(product.sku)),
      });
      updateTrackedSKUs(skusToTrack as string[]);
    }
  }, [trackedSKUs, visibleSKUs, _list]);

  useEffect(() => {
    if (visibleSKUs.size > 0) {
      sendImpressions();
    }
  }, [visibleSKUs]);

  const handleOnSlideChange = useCallback(
    (swiper: SwiperClass) => {
      if (!!loadingCollection) return;
      if (!hasNext) {
        return;
      }
      sendImpressions();
    },
    [_list]
  );

  useEffect(() => {
    if (!_list.length) return;
    initializeObserver();
  }, [_list]);

  return (
    <div
      id={identifier}
      className={classNames(
        "min-h-[200px] duration-300  opacity-0 invisible z-0 hidden overflow-hidden",
        {
          "!opacity-100 !visible  !block": activeTab === identifier,
        }
      )}
    >
      {!!loadingCollection && !_list.length && (
        <Swiper
          slidesPerView={1}
          breakpoints={{
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 10,
            },
          }}
        >
          {Array.from({ length: 3 }, (item, index: number) => {
            return (
              <SwiperSlide className={"border"} key={index}>
                <div className={styles.product_item}>
                  <FeaturedProductsLoading />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      {!!_list.length && (
        <Swiper
          onSwiper={setSwiperRef}
          // onAfterInit={handleAfterInit}
          onSlideChange={handleOnSlideChange}
          slidesPerView={1}
          // loop={true}
          navigation={{
            enabled: true,
          }}
          slidesPerGroup={1}
          breakpoints={{
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 10,
            },
          }}
          onRealIndexChange={handleRealIndexChange}
          modules={[Navigation]}
        >
          {_list?.map((data: Product, index: number) => {
            return (
              <SwiperSlide className="mb-0" key={index}>
                <ProductItem
                  data={data}
                  type="horizontal"
                  className={styles.product_item}
                />
                {/* <a
                  href={getUrlCustom({
                    pathname: PATH.ProductDetail,
                    query: data.variants?.[0]?.product.id
                      ? {
                          slug: data.slug,
                          variant: undefined,
                        }
                      : {
                          slug: data.slug,
                        },
                  })}
                  className={classNames(styles.product_item, "product-item")}
                  onClick={() => {
                    trackingSelectItem({
                      listKey: activeTab,
                      product: data,
                      index,
                    });
                  }}
                  data-sku={data.sku}
                >
                  <div className="flex-1 justify-start">
                    {" "}
                    <ProductImage data={data} />
                  </div>
                  <div className="flex flex-col flex-1 gap-1">
                    <ProductName name={data?.name || ""} lineClamp={3} />

                    <ProductPrice data={data} />
                  </div>
                </a> */}
              </SwiperSlide>
            );
          })}
          {hasNext &&
            Array.from({ length: _slidesPerView }, (item, index: number) => {
              return (
                <SwiperSlide className="px-2" key={index}>
                  <div className={styles.product_item}>
                    <FeaturedProductsLoading />
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      )}
      {!loadingCollection && !_list.length && (
        <div className="flex items-center justify-center h-[200px] w-full ">
          <Text type="title-3">Not Found Product</Text>
        </div>
      )}
    </div>
  );
};

export default FeatureProductItem;
export const getMaxLevelCategory = (categories: any[]): ProductCategory => {
  let maxLevel = 0;
  const maxLevelCategory: ProductCategory = categories?.reduce(
    (acc: ProductCategory = {} as ProductCategory, item: any) => {
      if (item.level > maxLevel) {
        maxLevel = item.level;
        acc = item;
      }
      return acc;
    },
    {} as ProductCategory
  );

  return maxLevelCategory;
};
