import React, { useEffect, useRef, useState } from "react";

type Props = {
  selector?: string;
  attributeData?: string;
};

const useImpressions = (
  { selector = ".product-item", attributeData = "data-sku" }: Props = {
    selector: ".product-item",
    attributeData: "data-sku",
  }
) => {
  const [trackedSKUs, setTrackedSKUs] = useState(new Set());
  // Ref for currently visible SKUs
  const [visibleSKUs, setVisibleSKUs] = useState(new Set());

  const observerRef = useRef<any>(null);

  /**
   * Handles visibility changes of IntersectionObserver entries.
   *
   * Iterates through the entries and updates the visibleSKUsRef set accordingly.
   * If an entry is intersecting, its SKU is added to the set. Otherwise, it's removed.
   *
   * @param {IntersectionObserverEntry[]} entries - An array of IntersectionObserver entries.
   * @return {void}
   */
  function handleVisibility(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const sku = entry.target.getAttribute("data-sku");
        if (sku) {
          setVisibleSKUs((prev) => {
            prev.add(sku);
            return new Set(prev);
          });
        }
      } else {
        const sku = entry.target.getAttribute("data-sku");
        if (sku) {
          setVisibleSKUs((prev) => {
            prev.delete(sku);
            return new Set(prev);
          });
        }
      }
    });
  }

  const initializeObserver = () => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
    observerRef.current = new IntersectionObserver(handleVisibility, {
      threshold: 0.5,
    });
    document.querySelectorAll(selector).forEach((item) => {
      const sku = item.getAttribute(attributeData);
      if (sku && !trackedSKUs.has(sku)) {
        // Kiểm tra xem SKU đã được track hay chưa
        observerRef.current.observe(item);
      }
    });
  };

  const updateTrackedSKUs = (skusToTrack: string[]) => {
    setTrackedSKUs((prev) => {
      const updatedSKUs = new Set(prev); // Create a new Set based on previous state
      skusToTrack.forEach((sku) => updatedSKUs.add(sku)); // Add new SKUs
      return updatedSKUs; // Return new Set
    });
  };

  useEffect(() => {
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  return {
    initializeObserver,
    visibleSKUs,
    trackedSKUs,
    updateTrackedSKUs,
    observer: observerRef.current,
  };
};

export default useImpressions;
