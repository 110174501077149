import React from "react";
import Skeleton from "react-loading-skeleton";
import Text from "../Text";

type Props = {};

const FeaturedProductsLoading = (props: Props) => {
  return (
    <>
      <div className="relative flex-1 aspect-square">
        <Skeleton className="aspect-square" />
      </div>
      <div className="flex flex-col flex-1 gap-1 my-auto">
        <Skeleton className="w-full" />
        <Skeleton className="w-full" />

        <div className="flex gap-2 w-full">
          <Skeleton containerClassName="flex-1" width="100%" />
          <Skeleton containerClassName="flex-1" width="50%" />
        </div>
      </div>
    </>
  );
};

export default FeaturedProductsLoading;
