import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { get } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import Button from '~/components/Button';
import Container from '~/components/Container';
import SectionTitle from '~/components/SectionTitle';
import Colors from '~/constants/colors';
import { Identifiers } from '~/constants/enum';
import { QUERY_GET_CMS_BLOCKS } from '~/data/home';
import styles from './FeaturedProducts.module.scss';
import FeatureProductItem from './FeatureProductItem';
import React from 'react';

interface FeaturedProductsProps {
    title?: string;
    numberOfItems?: number;
}

const FeaturedProducts: FC<FeaturedProductsProps> = ({ title = 'FEATURED PRODUCTS', numberOfItems = 3 }) => {
    const [selectedTab, setSelectedTab] = useState<string>('');

    const { data, loading } = useQuery(QUERY_GET_CMS_BLOCKS, {
        variables: {
            identifiers: Identifiers.FeatureProduct
        },
        context: { fetchOptions: { method: 'GET' } }
    });

    const content = get(data, 'cmsBlocks.items[0].content', '');

    const tabCategories = useMemo(() => {
        try {
            const regexCustomVar = new RegExp('(?<=var tab-categories=).*?(?=}})', 'g');
            const options = content.match(regexCustomVar)?.[0];
            const result = JSON.parse(options);
            setSelectedTab(result[0]?.value);
            return result;
        } catch (error) {
            return [];
        }
    }, [content]);

    useEffect(() => {
        if (tabCategories?.length && !selectedTab) {
            setSelectedTab(tabCategories[0].value);
        }
    }, [tabCategories?.length, selectedTab]);

    if (!tabCategories?.length) {
        return null;
    }

    return (
        <Container rootClassName={styles.featured_products_wrapper} className={styles.featured_products}>
            <SectionTitle
                text={title}
                textProps={{
                    type: 'headline-3',
                    color: Colors.GREY_900,
                    weight: 800
                }}
                action={
                    <div className={styles.categories}>
                        {tabCategories?.map((category: any) => (
                            <Button
                                key={category.value}
                                variant="secondary"
                                type={selectedTab === category.value ? 'solid' : 'outline'}
                                onClick={() => setSelectedTab(category.value)}
                                className={classNames('whitespace-nowrap', styles.category_item, {
                                    [styles.selected]: selectedTab === category.value
                                })}
                            >
                                {category.label.toLowerCase()}
                            </Button>
                        ))}
                    </div>
                }
            />
            <div className="relative">
                {tabCategories?.length &&
                    tabCategories?.map((category: any) => {
                        return (
                            <React.Fragment key={category.value}>
                                <FeatureProductItem identifier={category.value} activeTab={selectedTab} />
                            </React.Fragment>
                        );
                    })}
            </div>
        </Container>
    );
};

export default FeaturedProducts;
